import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#355C7D",
            light: "#6C5B7B",
        },
        secondary: {
            main: "#97c9e4",
            dark: "#65b4c5",
            contrastText: "white",
        },

        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '"Rajdhani"',
        ].join(","),
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
            margin: 0,
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: 1.5,
            margin: 0,
        },
        subtitle1: {
            fontSize: "1.5rem",
            fontWeight: 500,
            lineHeight: 1.75,
            margin: 0,
            color: "rgb(103, 119, 136)",
        },
        subtitle2: {
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: 1.75,
            margin: 0,
            color: "rgb(103, 119, 136)",
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: 1.66,
            margin: 0,
            color: "rgb(103, 119, 136)",
        },
        h1: {
            fontSize: "3.75rem",
            fontWeight: 700,
            lineHeight: 1.2,
            marginBottom: "0.35em",
        },
        h2: {
            fontSize: "3.25rem",
            fontWeight: 700,
            lineHeight: 1.3,
            margin: 0,
        },
        h3: {
            fontSize: "2.5rem",
            fontWeight: 700,
            lineHeight: 1.3,
            margin: 0,
        },
        h4: {
            fontSize: "2rem",
            fontWeight: 700,
            lineHeight: 1.3,
            margin: 0,
        },
        h5: {
            fontSize: "1.5rem",
            fontWeight: 700,
            lineHeight: 1.4,
            margin: 0,
        },
        h6: {
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: 1.6,
            margin: 0,
        },
    },
});

export default theme;
