import React from "react";
import { CircularProgress, Box } from "@mui/material";

const CenteredCircularProgress: React.FC = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="90vh"
        >
            <CircularProgress />
        </Box>
    );
};

export default CenteredCircularProgress;
