// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/auth";
import {
    connectFirestoreEmulator,
    initializeFirestore,
    persistentLocalCache,
    persistentMultipleTabManager,
    CACHE_SIZE_UNLIMITED,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyABfgIUmqBlEsdw7nALyMTDVcQGQjoyQ6s",
    authDomain: "notatia-frontend.firebaseapp.com",
    projectId: "notatia-frontend",
    storageBucket: "notatia-frontend.appspot.com",
    messagingSenderId: "678172506284",
    appId: "1:678172506284:web:e9f85b00e8c20118aa8709",
    measurementId: "G-8QQXKKYM50",
};

const app = initializeApp(firebaseConfig);
const firestore = initializeFirestore(app, {
    localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    }),
});
const firebaseAuth = getAuth(app);
if (process.env.NODE_ENV === "development") {
    connectFirestoreEmulator(firestore, "localhost", 8080);
    connectAuthEmulator(firebaseAuth, "http://localhost:9099");
}
export const firebaseApps = {
    app: app,
    auth: firebaseAuth,
    firestore: firestore,
};

export default firebaseApps;
