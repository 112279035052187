// import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
//eslint-disable-next-line
import React, { useEffect, useState } from "react";
//eslint-disable-next-line
import { DataGrid, GridColDef } from "@mui/x-data-grid";
// import CenteredCircularProgress from "../../../core/LoadingScreen";

//eslint-disable-next-line
const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
        field: "fullName",
        headerName: "Full name",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: (value, row) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
    },
];
function Users() {
    // eslint-disble-next-line
    // const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    // // eslint-disble-next-line
    // const [rows, setRows] = useState<Array<any>>([]);

    const getUsers = async () => {
        // const data = await getAuth(firebaseApps.app).getUsers([]);
        // data.users.forEach((user) => {
        //     console.log(user);
        // });
    };

    useEffect(() => {
        getUsers();
    });

    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            {/* <Grid container sx={{ m: 2 }} maxWidth="lg" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3">Users</Typography>
                </Grid>
                <Grid item xs={12}>
                    {dataLoaded ? (
                        <>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 25,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                                sx={{ overflow: "clip" }}
                            />
                        </>
                    ) : (
                        <>
                            <CenteredCircularProgress />
                        </>
                    )}
                </Grid>
            </Grid> */}
        </Box>
    );
}

export default Users;
