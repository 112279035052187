import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IToggleableKeyInputs } from "../../../pages/File/Editor/classes/SargamEncoder";
import { EditorFile } from "../../../pages/File/Editor/classes/EditorFile";

interface EditorState {
    enabled: boolean;
    taar:boolean;
    mandra:boolean;
    kanSwar:boolean;
    keyboardSettings:Record<IToggleableKeyInputs,boolean>;
    readOnlyMode:boolean;
    fileId:string|null;
    showHelp:boolean;
}

const initialState: EditorState = {
    enabled:true,
    taar:false,
    mandra:false,
    kanSwar:false,
    keyboardSettings:{
        "s":true,
        "r":false,
        "R":true,
        "g":false,
        "G":true,
        "m":true,
        "M":false,
        "p":true,
        "d":false,
        "D":true,
        "n":false,
        "N":true,
    },
    readOnlyMode:true,
    fileId:null,
    showHelp:false
};

const editorSlice = createSlice({
    name: "editor",
    initialState,
    reducers: {
        toggleEnabled: (state, action: PayloadAction<boolean>) => {
            state.enabled=action.payload
            if(action.payload){
                state.kanSwar=false
                state.mandra=false
                state.taar=false
            }
        },
        toggleTaar: (state, ) => {
            state.taar=!state.taar
            if(state.taar){
                state.mandra=false
            }
        },
        toggleMandra: (state,) => {
            state.mandra=!state.mandra
            if(state.mandra){
                state.taar=false
            }
        },
        toggleKanSwar: (state, ) => {
            state.kanSwar=!state.kanSwar
        },
        toggleKeyboardSettings:(state, action:PayloadAction<IToggleableKeyInputs>)=>{
            state.keyboardSettings[action.payload]=!state.keyboardSettings[action.payload]
        },
        toggleReadOnlyMode:(state)=>{
            if(state.fileId){
                if(!EditorFile.getInstance(state.fileId).getIfFileIsReadOnly()){
                    state.readOnlyMode=!state.readOnlyMode
                }
            }
        },
        setFileIdForEditor:(state,action:PayloadAction<string>)=>{
            state.fileId=action.payload;
            state.enabled=true;
            state.readOnlyMode=EditorFile.getInstance(state.fileId).getIfFileIsReadOnly();
        },
        toggleShowHelp:(state,action:PayloadAction<boolean>)=>{
            state.showHelp=action.payload
        }
    },
});

export const { toggleEnabled,toggleKanSwar,toggleMandra,toggleTaar,toggleKeyboardSettings,toggleReadOnlyMode,setFileIdForEditor, toggleShowHelp} =
    editorSlice.actions;

export default editorSlice.reducer;
