import {
    DocumentData,
    DocumentReference,
    doc,
    runTransaction,
    updateDoc,
} from "firebase/firestore";
import { v4 as uuid, v4 } from "uuid";
import {
    FirestoreFile,
    FirestoreTaal,
    FirestoreFileType,
    FileOptions,
} from "../../../../interfaces/firebase";
import firebaseApps from "../../../../config/firebase";

export type IRowRenderType = "simple" | "qwerty-sargam";

export const CONST_RowRenderTypes: IRowRenderType[] = [
    "simple",
    "qwerty-sargam",
];

export const MIN_SAVE_DURATION =
    (process.env.NODE_ENV === "development" ? 0.25 : 2) * 60 * 1000; // time in millisec

export type IReactStateFileCell = string[];
export type IReactStateFileCellOutbound = string;

export interface IReactStateFileVector {
    cells: IReactStateFileCell[];
}
export interface IReactStateFileVectorOutbound {
    cells: IReactStateFileCellOutbound[];
}

export interface IReactStateFileRowOutbound {
    vectors: IReactStateFileVectorOutbound[];
    options: {
        additionalRowVectorElements: number;
    };
    renderMethod: IRowRenderType[];
    subRowNames: string[];
}
export interface IReactStateFileRow {
    vectors: IReactStateFileVector[];
    options: {
        additionalRowVectorElements: number;
    };
    renderMethod: IRowRenderType[];
    subRowNames: string[];
}

export interface IReactStateFileSectionOutbound {
    rows: IReactStateFileRowOutbound[];
    name: string;
}

export interface IReactStateFileSection {
    rows: IReactStateFileRow[];
    name: string;
}

export interface IReactStateFileOutbound extends FirestoreFile {
    sections: IReactStateFileSectionOutbound[];
    // columnSettings: number[];
}

export interface IReactStateFile extends FirestoreFile {
    sections: IReactStateFileSection[];
    // columnSettings: number[];
}

export interface ISaveStatus {
    status:
        | "Initializing"
        | "Pending Save"
        | "Saved"
        | "Save Failed"
        | "Saving..."
        | "ReadOnly";
    error: boolean;
    lastSave: number;
    changesMade: boolean;
    lastChange: number;
    message?: string;
}

export class EditorFile {
    private static Instances: Record<string, EditorFile> = {};
    private file: IReactStateFile;
    private static fileUpdateListeners: Record<
        string,
        Record<string, React.MutableRefObject<(file: IReactStateFile) => void>>
    > = {};
    fileType: FirestoreFileType;
    fileId: string;
    fileTaal: FirestoreTaal;
    taalCount: number;
    sectionCount: number = 0;
    setFileRef: React.MutableRefObject<(file: IReactStateFile) => void>;
    saveStatusUpdateListeners: Record<
        string,
        Record<
            string,
            React.MutableRefObject<(saveStatus: ISaveStatus) => void>
        >
    > = {};
    localChangesCount: number = 0;
    firebaseRef: DocumentReference<DocumentData>;
    saveStatus: ISaveStatus;
    readOnly: boolean;

    // class methods
    private constructor(
        file: FirestoreFile,
        taal: FirestoreTaal,
        setFile: React.MutableRefObject<(file: IReactStateFile) => void>,
        userId:string
    ) {
        this.fileId = file.id;
        this.fileTaal = taal;
        this.taalCount = taal.detail.split("").length;
        this.fileType = file.type;
        this.file = this.tryParseIntoStateFile(file);
        this.sectionCount = this.file.sections.length;
        this.setFileRef = setFile;
        this.firebaseRef = doc(firebaseApps.firestore, "files", this.fileId);
        this.setFileRef.current(this.file);
        let t = Date.now();
        this.saveStatus = {
            error: false,
            status: "Saved",
            lastSave: t,
            changesMade: false,
            lastChange: t,
        };
        this.readOnly=userId!==file.owner;
        if(this.readOnly){
            this.saveStatus = {
                error: false,
                status: "ReadOnly",
                lastSave: t,
                changesMade: false,
                lastChange: t,
            };
        }
        this.updateAndPublishSaveStatus(this.saveStatus);
        this.publishFileToListeners(this.file);
    }

    static subscribeToFileUpdate(
        fileId: string,
        wrapper: React.MutableRefObject<(file: IReactStateFile) => void>
    ): string {
        const newuuid = uuid();
        if (typeof EditorFile.fileUpdateListeners[fileId] === "undefined") {
            EditorFile.fileUpdateListeners[fileId] = {};
        }
        EditorFile.fileUpdateListeners[fileId][newuuid] = wrapper;
        return newuuid;
    }

    static unsubscribeFromFileUpdate(fileId: string, wrapperID: string) {
        Object.keys(EditorFile.fileUpdateListeners).forEach((fileRecord) => {
            if (fileRecord === fileId) {
                try {
                    const fileUpdateListeners = {
                        ...EditorFile.fileUpdateListeners[fileRecord],
                    };
                    if (wrapperID in fileUpdateListeners) {
                        delete fileUpdateListeners[wrapperID];
                        EditorFile.fileUpdateListeners[fileRecord] =
                            fileUpdateListeners;
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        });
    }

    static deleteFileInstances(fileId: string) {
        Object.keys(EditorFile.Instances).forEach((fileRecord) => {
            if (fileRecord === fileId) {
                delete EditorFile.Instances[fileRecord];
            }
        });
    }

    static getInstanceCount() {
        return EditorFile.Instances.length;
    }

    static getInstance(
        fileId: string,
        file?: FirestoreFile,
        taal?: FirestoreTaal,
        userId?:string,
        setFile?: React.MutableRefObject<(file: IReactStateFile) => void>
    ): EditorFile {
        var foundInstance = false;
        // for (let i = 0; i < EditorFile.Instances.length; i++) {
        //     const element = EditorFile.Instances[i];
        //     if (element.fileId === fileId) {
        //         foundInstance = true;
        //         return element;
        //     }
        // }
        Object.keys(EditorFile.Instances).forEach((fileIdRecord, index) => {
            if (fileId === fileIdRecord) {
                foundInstance = true;
                // return EditorFile.Instances[fileIdRecord];
            }
        });
        if (!foundInstance) {
            if (
                typeof file === "undefined" ||
                typeof taal === "undefined" ||
                typeof setFile === "undefined"||
                typeof userId ==="undefined"
            ) {
                throw new Error(
                    "File loaded with insufficient information"
                );
            } else {
                const newInstance = new EditorFile(file, taal, setFile, userId);
                EditorFile.Instances[fileId] = newInstance;
                // EditorFile.Instances.push(newInstance);
                // console.log("new instance", EditorFile.Instances);
                return newInstance;
            }
        } else {
            return EditorFile.Instances[fileId];
        }
    }

    // transmit save statuses
    private updateAndPublishSaveStatus(error: ISaveStatus) {
        if(!this.readOnly){
            this.saveStatus = error;
            //this.setSaveStatusWrappers.current(error);
            // this.saveStatusUpdateListeners.forEach((wrapper) => {
            //     wrapper.current(error);
            // });
            Object.keys(this.saveStatusUpdateListeners).forEach((fileId, index) => {
                if (fileId === this.file.id) {
                    Object.keys(
                        this.saveStatusUpdateListeners[this.fileId]
                    ).forEach((uid, index) => {
                        this.saveStatusUpdateListeners[this.fileId][uid].current(
                            error
                        );
                    });
                }
            });
        }
    }

    // eslint-disable-next-line
    private async saveToTransaction() {
        try {
            await runTransaction(
                firebaseApps.firestore,
                async (transaction) => {
                    // eslint-disable-next-line
                    const documentSnapshot = await transaction.get(
                        this.firebaseRef
                    );
                    transaction.set(this.firebaseRef, this.file);
                }
            );
        } catch (e: any) {
            this.updateAndPublishSaveStatus({
                ...this.saveStatus,
                error: true,
                status: e.message,
            });
        }
    }

    private packFileToOutboundFormat(): IReactStateFileOutbound {
        let tempFile = {
            ...this.file,
        };

        const tempFileSections: IReactStateFileSectionOutbound[] =
            tempFile.sections.map((section) => {
                const sectionRows = section.rows.map((row) => {
                    const rowVectors = row.vectors.map(
                        (vector: IReactStateFileVector) => {
                            const vectorCells = vector.cells.map(
                                (cell: string[] | string) => {
                                    if (typeof cell !== "string") {
                                        cell = cell.join(",");
                                    }
                                    return cell as IReactStateFileCellOutbound;
                                }
                            );
                            return {
                                ...vector,
                                cells: vectorCells,
                            } as unknown as IReactStateFileVectorOutbound;
                        }
                    );
                    return {
                        ...row,
                        vectors: rowVectors,
                    } as unknown as IReactStateFileRowOutbound;
                });
                return {
                    ...section,
                    rows: sectionRows,
                } as unknown as IReactStateFileSectionOutbound;
            });

        const newTempFile: IReactStateFileOutbound = {
            ...tempFile,
            sections: tempFileSections,
        };
        return newTempFile;
    }

    // save
    private async saveToFireStore() {
        this.updateAndPublishSaveStatus({
            ...this.saveStatus,
            status: "Saving...",
        });
        // console.log("saving", this.file);
        const packedFile = this.packFileToOutboundFormat();
        try {
            await updateDoc(
                this.firebaseRef,
                packedFile as { [x: string]: any }
            );
            let t = Date.now();
            this.updateAndPublishSaveStatus({
                error: false,
                status: `Saved`,
                lastSave: t,
                changesMade: false,
                lastChange: t,
            });
        } catch (e: any) {
            console.error(e);
            this.updateAndPublishSaveStatus({
                ...this.saveStatus,
                error: true,
                status: "Save Failed",
                message: e.message,
            });
        }
    }

    private async checkSaveRequired() {
        if (Date.now() >= this.saveStatus.lastSave + MIN_SAVE_DURATION && !this.readOnly) {
            await this.saveToFireStore();
        } else {
            this.updateAndPublishSaveStatus({
                ...this.saveStatus,
                status: `Pending Save`,
            });
        }
    }

    private publishFileToListeners(file: IReactStateFile) {
        Object.keys(EditorFile.fileUpdateListeners).forEach((fileId, index) => {
            if (fileId === file.id) {
                Object.keys(
                    EditorFile.fileUpdateListeners[this.fileId]
                ).forEach((uid, index) => {
                    EditorFile.fileUpdateListeners[this.fileId][uid].current(
                        file
                    );
                });
            }
        });
    }

    private updateFile(newFile: IReactStateFile) {
        this.file = {
            ...newFile,
        };
        this.setFileRef.current(this.file);
        //this.checkSaveRequired();
        this.updateAndPublishSaveStatus({
            ...this.saveStatus,
            status: "Pending Save",
            lastChange: Date.now(),
            changesMade: true,
        });
        this.publishFileToListeners(this.file);
    }

    private tryParseIntoStateFile(file: FirestoreFile): IReactStateFile {
        const fileKeys = Object.keys(file);
        let returnFile = file as any;
        if (fileKeys.indexOf("sections") === -1) {
            //file is completely new
            returnFile = {
                ...returnFile,
                sections: [this.getBlankSection(file.fileOptions)],
            };
        }
        if (fileKeys.indexOf("columnSettings") === -1) {
            returnFile = {
                ...returnFile,
                columnSettings: Array<number>(this.taalCount).fill(0),
            };
        }

        returnFile = this.parseCommaSeparatedTextToArray(returnFile);

        const t: IReactStateFile = returnFile;
        return t;
    }

    private parseCommaSeparatedTextToArray(
        file: IReactStateFileOutbound | IReactStateFile
    ): IReactStateFile {
        const tempFile = { ...file };

        const tempFileSections: IReactStateFileSection[] =
            tempFile.sections.map((section) => {
                const sectionRows = section.rows.map((row) => {
                    const rowVectors = row.vectors.map((vector: any) => {
                        const vectorCells = vector.cells.map(
                            (cell: string[] | string) => {
                                if (typeof cell === "string") {
                                    cell = cell.split(",");
                                }
                                return cell as IReactStateFileCell;
                            }
                        );
                        return {
                            ...vector,
                            cells: vectorCells,
                        } as unknown as IReactStateFileVector;
                    });
                    return {
                        ...row,
                        vectors: rowVectors,
                    } as unknown as IReactStateFileRow;
                });
                return {
                    ...section,
                    rows: sectionRows,
                } as unknown as IReactStateFileSection;
            });
        const newTempFile: IReactStateFile = {
            ...tempFile,
            sections: [...tempFileSections],
        };
        return newTempFile;
    }

    private getBlankSubCell() {
        return "";
    }

    private getBlankCell(fileOptions?: FileOptions): IReactStateFileCell {
        //TODO: Manage FileType
        const a = [];
        a.push(this.getBlankSubCell());
        return a;
    }

    private getBlankVector(fileOptions?: FileOptions): IReactStateFileVector {
        const tempCells = [];
        const vectorLength =
            typeof fileOptions === "undefined"
                ? this.file.fileOptions.rows.vectorLength
                : fileOptions.rows.vectorLength;
        for (let i = 0; i < vectorLength; i++) {
            tempCells.push(this.getBlankCell());
        }
        return {
            cells: [...tempCells],
        };
    }

    // private getBlankSubRowNames(fileOptions?: FileOptions): string[] {
    //     const tempCells = [];
    //     const vectorLength =
    //         typeof fileOptions === "undefined"
    //             ? this.file.fileOptions.rows.vectorLength
    //             : fileOptions.rows.vectorLength;
    //     for (let i = 0; i < vectorLength; i++) {
    //         tempCells.push(`Track #${i + 1}`);
    //     }
    //     return [...tempCells];
    // }

    private getBlankRow(fileOptions?: FileOptions): IReactStateFileRow {
        let vectors: IReactStateFileVector[] = [];
        for (let index = 0; index < this.taalCount; index++) {
            vectors.push({ ...this.getBlankVector(fileOptions) });
        }
        return {
            vectors: [...vectors],
            options: {
                additionalRowVectorElements: 0,
            },
            subRowNames: [],
            renderMethod: [],
        };
    }

    private getBlankSection(fileOptions?: FileOptions): IReactStateFileSection {
        const blankRow =
            typeof fileOptions === "undefined"
                ? this.getBlankRow()
                : this.getBlankRow(fileOptions);
        return {
            rows: [blankRow],
            name: `New Section ${this.sectionCount + 1}`,
        };
    }

    getFileOptions() {
        return this.file.fileOptions;
    }



    unsubscribeFromSaveStatus(fileId: string, uuid: string) {
        Object.keys(this.saveStatusUpdateListeners).forEach((fileRecord) => {
            if (fileRecord === fileId) {
                try {
                    const saveStatusListeners = {
                        ...this.saveStatusUpdateListeners[fileRecord],
                    };
                    if (uuid in saveStatusListeners) {
                        delete saveStatusListeners[uuid];
                        this.saveStatusUpdateListeners[fileRecord] =
                            saveStatusListeners;
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        });
    }

    subscribeToSaveStatus(
        fileId: string,
        wrapper: React.MutableRefObject<(saveStatus: ISaveStatus) => void>
    ): string {
        let uuid = v4();
        if (typeof this.saveStatusUpdateListeners[fileId] === "undefined") {
            this.saveStatusUpdateListeners[fileId] = {};
        }
        this.saveStatusUpdateListeners[fileId][uuid] = wrapper;
        // this.saveStatusUpdateListeners.push(wrapper);
        wrapper.current(this.saveStatus);
        // console.log("save status", this.saveStatus.status);
        return uuid;
    }

    async trySave() {
        await this.checkSaveRequired();
    }

    addAdditionalVectorElementInARow(
        sectionIndex: number,
        rowIndex: number,
        count: number = 1
    ) {
        const tempFile = { ...this.file };
        const section = tempFile.sections[sectionIndex];
        const row = section.rows[rowIndex];
        row.vectors.forEach((vector) => {
            const cells = [];
            for (let i = 0; i < vector.cells[0].length; i++) {
                cells.push(this.getBlankSubCell());
            }
            vector.cells.push(cells);
        });
        row.options.additionalRowVectorElements += count;
        row.subRowNames.push(`Track #${row.vectors[0].cells.length}`);
        row.renderMethod.push("simple");

        this.updateFile({ ...tempFile });
    }

    removeAdditionalVectorElementInARow(
        sectionIndex: number,
        rowIndex: number,
        count: number = 1
    ) {
        const tempFile = { ...this.file };
        const section = tempFile.sections[sectionIndex];
        const row = section.rows[rowIndex];
        if (row.options.additionalRowVectorElements > 0) {
            row.vectors.forEach((vector) => {
                vector.cells.pop();
            });
            row.options.additionalRowVectorElements -= count;
            row.subRowNames.pop();
            this.updateFile({ ...tempFile });
        }
    }

    addRow(sectionIndex: number, position?: number) {
        const tempFile = this.file;
        const givenSection = tempFile.sections[sectionIndex];
        let updatedRows;
        if (typeof position === "undefined") {
            updatedRows = [...givenSection.rows, this.getBlankRow()];
        } else {
            updatedRows = [
                ...givenSection.rows.slice(0, position),
                this.getBlankRow(),
                ...givenSection.rows.slice(position),
            ];
        }
        const updatedSection = {
            ...givenSection,
            rows: [...updatedRows],
        };
        tempFile.sections[sectionIndex] = updatedSection;
        this.updateFile(tempFile);
    }

    addSection(position?: number) {
        const tempFile = { ...this.file };
        let section = this.getBlankSection();
        if (typeof position === "undefined") {
            // adding section at last
            tempFile.sections.push(section);
        } else {
            tempFile.sections = [
                ...tempFile.sections.slice(0, position),
                section,
                ...tempFile.sections.slice(position),
            ];
        }
        this.updateFile(tempFile);
        this.sectionCount++;
    }

    deleteSection(sectionIndex: number) {
        const thisFile = { ...this.file };
        thisFile.sections = [
            ...thisFile.sections.slice(0, sectionIndex),
            ...thisFile.sections.slice(sectionIndex + 1),
        ];
        this.updateFile(thisFile);
    }

    moveSectionUp(sectionIndex: number) {
        if (sectionIndex > 0 && sectionIndex < this.sectionCount) {
            const tempFile = this.file;
            const updatedSections = [...tempFile.sections];
            const temp = updatedSections[sectionIndex];
            updatedSections[sectionIndex] = updatedSections[sectionIndex - 1];
            updatedSections[sectionIndex - 1] = temp;
            tempFile.sections = [...updatedSections];
            this.updateFile(tempFile);
        } else {
            //do nothing
            return;
        }
    }

    moveSectionDown(sectionIndex: number) {
        if (sectionIndex >= 0 && sectionIndex < this.sectionCount - 1) {
            const tempFile = this.file;
            const updatedSections = [...tempFile.sections];
            const temp = updatedSections[sectionIndex];
            updatedSections[sectionIndex] = updatedSections[sectionIndex + 1];
            updatedSections[sectionIndex + 1] = temp;
            tempFile.sections = [...updatedSections];
            // console.log(tempFile.sections);
            this.updateFile(tempFile);
        } else {
            //do nothing
            return;
        }
    }

    moveRowUp(sectionIndex: number, rowIndex: number) {
        if (sectionIndex >= 0 && sectionIndex < this.sectionCount) {
            const tempFile = { ...this.file };
            const section = { ...tempFile.sections[sectionIndex] };
            if (rowIndex > 0 && rowIndex < section.rows.length) {
                const tempRow = section.rows[rowIndex];
                section.rows[rowIndex] = section.rows[rowIndex - 1];
                section.rows[rowIndex - 1] = tempRow;
                tempFile.sections[sectionIndex] = section;
                this.updateFile(tempFile);
            } else {
                return;
            }
        } else {
            //do nothing
            return;
        }
    }

    moveRowDown(sectionIndex: number, rowIndex: number) {
        if (sectionIndex >= 0 && sectionIndex < this.sectionCount) {
            const tempFile = { ...this.file };
            const section = { ...tempFile.sections[sectionIndex] };
            if (rowIndex >= 0 && rowIndex < section.rows.length - 1) {
                const tempRow = section.rows[rowIndex];
                section.rows[rowIndex] = section.rows[rowIndex + 1];
                section.rows[rowIndex + 1] = tempRow;
                tempFile.sections[sectionIndex] = section;
                this.updateFile(tempFile);
            } else {
                return;
            }
        } else {
            //do nothing
            return;
        }
    }

    deleteRow(sectionIndex: number, rowIndex: number) {
        const thisFile = { ...this.file };
        const section = thisFile.sections[sectionIndex];
        if (section.rows.length > 1) {
            section.rows = [
                ...section.rows.slice(0, rowIndex),
                ...section.rows.slice(rowIndex + 1),
            ];
            this.updateFile(thisFile);
        } else {
            return;
        }
    }

    rotateRowLeft(sectionIndex: number, rowIndex: number) {
        const tempFile = { ...this.file };
        const section = tempFile.sections[sectionIndex];
        const row = section.rows[rowIndex];
        // row.subRows.forEach((subRow)=>{
        //     const rotatedRow = subRow.cells.slice(1);
        //     rotatedRow.push(subRow.cells[0]);
        // })
        const fElement = row.vectors.splice(0, 1);
        row.vectors.push(fElement[0]);
        // const rotatedRow: IReactStateFileCells = { subRows: [] };
        // row.subRows.forEach((subRow) => {
        //     const rotatedSubRow: IReactStateFileVector = { cells: [] };

        //     subRow.cells.forEach((cellArr) => {
        //         const rotatedCells: IReactStateFileRow = [
        //             ...cellArr.slice(1),
        //             cellArr[0],
        //         ];
        //         rotatedSubRow.cells.push(rotatedCells);
        //     });

        //     rotatedRow.subRows.push(rotatedSubRow);
        // });
        // section.rows[rowIndex] = { ...rotatedRow };
        // tempFile.sections[sectionIndex] = section;
        this.updateFile(tempFile);
    }

    rotateRowRight(sectionIndex: number, rowIndex: number) {
        const tempFile = { ...this.file };
        const section = tempFile.sections[sectionIndex];
        const row = section.rows[rowIndex];
        // const rotatedArray = [...row.cells];
        // const lastElement = rotatedArray.pop();
        // rotatedArray.unshift(lastElement!);

        const lElement = row.vectors.pop();
        if (typeof lElement === "undefined") {
            throw new Error("Insufficient vectors in row.");
        }
        row.vectors = [lElement, ...row.vectors];
        // const rotatedRow: IReactStateFileCells = { subRows: [] };

        // row.subRows.forEach((subRow) => {
        //     const rotatedSubRow: IReactStateFileVector = { cells: [] };

        //     subRow.cells.forEach((cellArr) => {
        //         const rotatedCells: IReactStateFileRow = [
        //             ...cellArr.slice(-1),
        //             ...cellArr.slice(0, -1),
        //         ];
        //         rotatedSubRow.cells.push(rotatedCells);
        //     });

        //     rotatedRow.subRows.push(rotatedSubRow);
        // });

        // section.rows[rowIndex] = { ...rotatedRow };
        // tempFile.sections[sectionIndex] = section;
        this.updateFile(tempFile);
    }

    setSectionName(index: number, name: string) {
        const thisFile = { ...this.file };
        thisFile.sections[index].name = name;
        this.updateFile(thisFile);
    }

    getSectionName(index: number) {
        return this.file.sections[index].name;
    }

    setSubRowName(
        subRowIndex: number,
        genericSubRow: boolean,
        newSubRowName: string,
        sectionIndex: number,
        rowIndex: number
    ) {
        const tempFile = { ...this.file };
        if (genericSubRow) {
            if(tempFile.fileOptions.subRowNames.indexOf(newSubRowName)!==-1){
                throw new Error("Track Name Exists.")
            }
            const beforeValue = tempFile.fileOptions.subRowNames[subRowIndex]
            tempFile.fileOptions.subRowNames[subRowIndex] = newSubRowName;
            if(tempFile.fileOptions.summarySubRow===beforeValue)
            {
                tempFile.fileOptions.summarySubRow=newSubRowName;
            }
        } else {
            tempFile.sections[sectionIndex].rows[rowIndex].subRowNames[
                subRowIndex
            ] = newSubRowName;
        }


        this.updateFile({ ...tempFile });
    }

    getSubRowName(
        subRowIndex: number,
        genericSubRow: boolean,
        sectionIndex: number,
        rowIndex: number
    ) {
        return genericSubRow
            ? this.file.fileOptions.subRowNames[subRowIndex]
            : this.file.sections[sectionIndex].rows[rowIndex].subRowNames[
                  subRowIndex
              ];
    }

    insertSubCell(sectionIndex: number, rowIndex: number, vectorIndex: number) {
        const tempFile = { ...this.file };
        const section = tempFile.sections[sectionIndex];
        const row = section.rows[rowIndex];
        // row.subRows.forEach((subRow) => {
        //     subRow.cells.forEach((cellArr) => {
        //         cellArr.push("");
        //     });
        // });
        row.vectors[vectorIndex].cells.forEach((cell) => {
            cell.push(this.getBlankSubCell());
        });
        // const cell = row.cells[cellIndex];
        // cell.forEach((subRow) => {
        //     subRow.push("");
        // });
        // cell.u.push("");
        // if (tempFile.type === "Advanced Notation") {
        //     (cell as IReactStateFileCellNPlus).l.push("");
        // }
        this.updateFile({ ...tempFile });
    }

    moveSubCell(
        sectionIndex: number,
        rowIndex: number,
        vectorIndex: number,
        subCellIndex: number, // the cell user intends to move
        move: "left" | "right"
    ) {
        // console.log(sectionIndex, rowIndex, cellIndex, subCellIndex, move);
        const tempFile = { ...this.file };
        const section = tempFile.sections[sectionIndex];
        const row = section.rows[rowIndex];
        const vector = row.vectors[vectorIndex];
        if (move === "left" && subCellIndex > 0) {
            vector.cells.forEach((cell) => {
                const fElement = cell.splice(0, 1);
                cell.push(fElement[0]);
            });
            // row.subRows.forEach((subRow) => {
            //     subRow.cells.forEach((cellArr) => {
            //         const firstElement = cellArr[0];
            //         const rotatedCells = [...cellArr.slice(1), firstElement];
            //         cellArr.splice(0, cellArr.length, ...rotatedCells);
            //     });
            // });
        } else if (
            move === "right" &&
            subCellIndex >= 0 //&&
            //subCellIndex < vector.cells[0].length - 1
        ) {
            vector.cells.forEach((cell) => {
                const lastElement = cell.pop();
                cell.unshift(lastElement!);
            });
            // row.subRows.forEach((subRow) => {
            //     subRow.cells.forEach((cellArr) => {
            //         const lastIndex = cellArr.length - 1;
            //         const rotatedCells = [
            //             cellArr[lastIndex],
            //             ...cellArr.slice(0, lastIndex),
            //         ];
            //         cellArr.splice(0, cellArr.length, ...rotatedCells);
            //     });
            // });
        } else {
            return;
        }
        this.updateFile({ ...tempFile });
    }

    removeSubCell(
        sectionIndex: number,
        rowIndex: number,
        vectorIndex: number,
        subCellIndex: number
    ) {
        const tempFile = { ...this.file };
        const section = tempFile.sections[sectionIndex];
        const row = section.rows[rowIndex];
        const vector = row.vectors[vectorIndex];
        if (vector.cells[0].length > 1) {
            vector.cells.forEach((cell) => {
                cell.splice(subCellIndex, 1);
            });
            // row.subRows.forEach((subRow) => {
            //     subRow.cells.forEach((cellArr) => {
            //         if (subCellIndex >= 0 && subCellIndex < cellArr.length) {
            //             cellArr.splice(subCellIndex, 1);
            //         }
            //     });
            // });
            // if (tempFile.type === "Advanced Notation") {
            //     (cell as IReactStateFileCellNPlus).l.splice(subCellIndex, 1);
            // }
        } else {
            vector.cells.forEach((cell) => {
                cell = this.getBlankCell();
            });
            // row.subRows.forEach((subRow) => {
            //     subRow.cells.forEach((cellArr) => {
            //         cellArr[subCellIndex] = "";
            //     });
            // });
        }
        this.updateFile({ ...tempFile });
    }

    updateValueAtCell(
        sectionIndex: number,
        rowIndex: number,
        vectorIndex: number,
        cellIndex: number,
        subCellIndex: number,
        newSubCellValue: string
    ) {
        // console.log(
        //     sectionIndex,
        //     rowIndex,
        //     vectorIndex,
        //     cellIndex,
        //     subCellIndex,
        //     newSubCellValue
        // );
        const tempFile = { ...this.file };
        const section = tempFile.sections[sectionIndex];
        const row = section.rows[rowIndex];
        const vector = row.vectors[vectorIndex];
        const cell = vector.cells[cellIndex];
        // const cell = [...row.subRows[subRowIndex].cells[cellIndex]];
        // console.log(
        //     this.file.fileOptions.rows.vectorLength,
        //     row.options.additionalRowVectorElements,
        //     Math.max(
        //         this.file.fileOptions.rows.vectorLength,
        //         row.options.additionalRowVectorElements
        //     )
        // );
        if (
            vectorIndex >= 0 &&
            vectorIndex < row.vectors.length &&
            cellIndex >= 0 &&
            cellIndex <
                this.file.fileOptions.rows.vectorLength +
                    row.options.additionalRowVectorElements &&
            subCellIndex >= 0 &&
            subCellIndex < cell.length
        ) {
            cell[subCellIndex] = newSubCellValue;
        } else {
            throw new Error("Could not find cell to update");
        }

        this.updateFile({
            ...tempFile,
            // sections: [...updatedFileSections],
        });
    }

    setSummaryTrackName(trackName: string) {
        if (this.file.fileOptions.subRowNames.indexOf(trackName) !== -1) {
            const tempFile = { ...this.file };
            tempFile.fileOptions.summarySubRow = trackName;
            this.updateFile({ ...tempFile });
        } else {
            console.warn("Tried to set unknown track as summary");
        }
    }

    setTrackType(
        sectionIndex: number,
        rowIndex: number,
        subRowIndex: number,
        genericSubRow: boolean,
        type: IRowRenderType
    ) {
        const tempFile = { ...this.file };
        if (genericSubRow) {
            tempFile.fileOptions.subRowRenderTypes[subRowIndex] = type;
        } else {
            tempFile.sections[sectionIndex].rows[rowIndex].renderMethod[
                subRowIndex
            ] = type;
        }
        this.updateFile({ ...tempFile });
    }

    // eslint-disable-next-line
    getTrackType(sectionIndex: number, rowIndex: number, trackIndex: number) {}


    getIfFileIsReadOnly(){
        return this.readOnly;
    }
}
