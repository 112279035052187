import React from "react";
import { Box, Container, Typography } from "@mui/material";

interface IState {
    error: null | any;
    errorInfo: null | any;
}

class ErrorBoundary extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <Container maxWidth="md">
                    <Box sx={{ mt: 1 }}>
                        <Typography variant="h4">
                            Something went wrong.
                        </Typography>
                        <details style={{ whiteSpace: "pre-wrap" }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </Box>
                </Container>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
