import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
    AppBar,
    Button,
    Divider,
    Drawer,
    IconButton,
    // Link,
    List,
    ListItem,
    ListItemButton,
    // ListItemText,
    // Slide,
    Toolbar,
    Typography,
} from "@mui/material";

import { Box, Theme, styled } from "@mui/system";
import ROUTES from "../core/routes";
import { NavLink, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../App";
import { useAppSelector } from "../core/store/hooks";
import { RootState } from "../core/store/store";

interface IStyledProps {
    theme: Theme;
}
const StyledLink = styled(Box)((prop: IStyledProps) => ({
    "a.active": {
        borderBottom: `2px solid ${prop.theme.palette.secondary.main}`,
    },
    a: {
        padding: `4px 0`,
        textDecoration: "none",
        color: prop.theme.palette.primary.contrastText,
        fontWeight: 500,
    },
}));

const drawerWidth = 240;

function NavBar() {
    const userContext = useContext(AuthContext);
    const navigate = useRef(useNavigate());
    const authIsLoaded = useAppSelector(
        (state: RootState) => state.firebase.auth.isLoaded
    );

    const [showAdmin, setShowAdmin] = useState<boolean>(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (userContext.isAuthenticated) {
            userContext.auth
                ?.getIdTokenResult()
                .then((idTokens) => {
                    setShowAdmin(idTokens.claims.roles.indexOf("admin") !== -1);
                })
                .catch(() => {});
        }
    }, [userContext, authIsLoaded]);

    const navLinks = useMemo(() => {
        const dashboardLink = userContext.isAuthenticated
            ? [
                  {
                      label: "Dashboard",
                      href: `${ROUTES.dashboard}`,
                  },
              ]
            : [];
        const userActionLinks = userContext.isAuthenticated
            ? [
                  {
                      label: "Sign Out",
                      href: `${ROUTES.logout}`,
                  },
              ]
            : [
                  {
                      label: "Sign In",
                      href: `${ROUTES.signIn}`,
                  },
              ];

        var links = [
            { label: "Home", href: `${ROUTES.home}` },
            ...dashboardLink,
            ...userActionLinks,
        ];

        if (showAdmin && userContext.isAuthenticated) {
            links = [
                {
                    label: "Admin",
                    href: `${ROUTES.admin}`,
                },
                ...links,
            ];
        }

        return links;
    }, [userContext, showAdmin]);

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Button
                onClick={() => {
                    navigate.current(`${ROUTES.home}`);
                }}
            >
                <Typography variant="h6" component="span" sx={{ m: 1 }}>
                    Notatia
                </Typography>
            </Button>
            <Divider />
            <List>
                {navLinks.map((link, index) => (
                    <ListItem disablePadding key={index}>
                        <ListItemButton>
                            {/* <ListItemText primary={link.label}  /> */}
                            <Button
                                onClick={() => {
                                    navigate.current(`${link.href}`);
                                }}
                                sx={{ textAlign: "center" }}
                            >
                                {link.label}
                            </Button>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container =
        window !== undefined ? () => window.document.body : undefined;

    return (
        <Box sx={{ mb: 7 }}>
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: "flex", flexGrow: 1 }}>
                        <Button
                            disableTouchRipple
                            disableElevation
                            disableFocusRipple
                            onClick={() => {
                                navigate.current(`${ROUTES.home}`);
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="span"
                                sx={{ color: "white" }}
                            >
                                Notatia
                            </Typography>
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                        {navLinks.map((link) => (
                            <StyledLink key={link.href} sx={{ mr: 2 }}>
                                <NavLink
                                    className={({ isActive }) =>
                                        isActive ? "active" : ""
                                    }
                                    to={link.href}
                                >
                                    {link.label}
                                </NavLink>
                            </StyledLink>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>

            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default NavBar;
