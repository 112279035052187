import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { AuthContext } from "../App";
import ROUTES from "../core/routes";
import { Box } from "@mui/system";
import firebaseApps from "../config/firebase";

const Logout = () => {
    const navigate = useRef(useNavigate());
    const authContext = useContext(AuthContext);
    useEffect(() => {
        const goToHome = () => {
            navigate.current(ROUTES.home);
        };
        const handleLogout = async () => {
            try {
                await firebaseApps.auth.signOut();
                await firebaseApps.auth.updateCurrentUser(null);
                goToHome();
            } catch (error) {
                console.log("Logout Error:", error);
            }
        };
        handleLogout();
    }, [authContext]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "92vh",
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignContent: "center",
                        justifyContent: "center",
                        mb: 2,
                    }}
                >
                    <CircularProgress sx={{ alignSelf: "center" }} />
                </Box>
                <Typography>Please wait while we log you out.</Typography>
            </Box>
        </Box>
    );
};

export default Logout;
