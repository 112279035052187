import { collection, getDocs} from "firebase/firestore";
import firebaseApps from "../config/firebase";
import { FirestoreTaal } from "../interfaces/firebase";
import { verifyKeys } from "./firebase/verifyData";


export const COMMON_SCRIPT_fetchTaal = async ():Promise<FirestoreTaal[]> => {
    const querySnapshot = await getDocs(
        collection(firebaseApps.firestore, "taal"),
    );
    const tempTaal: FirestoreTaal[] = [];
    querySnapshot.forEach((doc) => {
        let t = doc.data();
        if (verifyKeys(t, {} as FirestoreTaal)) {
            tempTaal.push({
                ...doc.data(),
                id: doc.id,
            } as FirestoreTaal);
        }
    });

    return tempTaal
}