import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";

import TaalEditor from "./TaalEditor/index";
import Users from "./users/Users";

// {"roles":["admin"]}
interface ITabElement {
    tabName: string;
    element: JSX.Element;
    disabled: boolean;
    tabNumber: number;
}

const tabAndElement: Array<ITabElement> = [
    {
        tabName: "Taal Editor",
        element: <TaalEditor />,
        disabled: false,
        tabNumber: 0,
    },
    {
        tabName: "Users",
        element: <Users />,
        disabled: false,
        tabNumber: 1,
    },
    {
        tabName: "File Stats",
        element: <></>,
        disabled: true,
        tabNumber: 2,
    },
];

function AdminPage() {
    const [value, setValue] = useState<number>(0);
    const [renderTabList, setRenderTabList] = useState<ITabElement | null>(
        null
    );

    useEffect(() => {
        setRenderTabList(tabAndElement[value]);
    }, [value]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Grid container maxWidth={"xl"}>
                <Grid sx={{ flexGrow: 1 }} item xl={12} xs={12}>
                    <Tabs value={value} onChange={handleChange} centered>
                        {tabAndElement.map((child, index) => (
                            <Tab
                                key={index}
                                label={`${child.tabName}`}
                                disabled={child.disabled}
                            />
                        ))}
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    {renderTabList?.element}
                </Grid>
            </Grid>
        </Box>
    );
}

export default AdminPage;
