import { Route, Navigate, Routes } from "react-router-dom";
// import SignIn from "../pages/SignIn";
import ROUTES from "./routes";
// import SignUp from "../pages/SignUp";
import ErrorBoundary from "./ErrorBoundary";
import Logout from "../pages/Logout";
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import CenteredCircularProgress from "./LoadingScreen";
import VerifyEmail from "../pages/error/VerifyEmail";
import EmailVerification from "../pages/Dashboard/components/EmailVerification";
import AdminPage from "../pages/Admin/AdminPage";
import BrokerAuth from "../pages/BrokerAuth";
import { AuthContext } from "../App";
import { collection, getDocs, query, where } from "firebase/firestore";
import firebaseApps from "../config/firebase";

const LandingPage = lazy(() => import("../pages/LandingPage"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const NotationFileEditor = lazy(() => import("../pages/File/Editor"));

const Router = () => {
    const userContext = useContext(AuthContext);
    const [allowAdmin, setAllowAdmin] = useState<boolean>(false);
    const [doc, setDoc] = useState<Record<string, string | boolean>>({});

    useEffect(() => {
        userContext.auth
            ?.getIdTokenResult()
            .then((idTokens) => {
                setAllowAdmin(idTokens.claims.roles.indexOf("admin") !== -1);
            })
            .catch(() => {});
    });

    const getInfo = async () => {
        try {
            const ref = query(
                collection(firebaseApps.firestore, "parameters"),
                where("active", "==", true)
            );
            const doc = (await getDocs(ref)).docs[0].data();
            setDoc(doc);
        } catch {}
    };

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <>
            {
                <ErrorBoundary>
                    <Routes>
                        <Route
                            path={`${ROUTES.signIn}`}
                            element={<BrokerAuth />}
                        />
                        <Route path={`${ROUTES.logout}`} element={<Logout />} />
                        {(allowAdmin || doc.overrideAdminRoute) && (
                            <Route
                                path={`${ROUTES.admin}`}
                                element={
                                    <Suspense
                                        fallback={<CenteredCircularProgress />}
                                    >
                                        <AdminPage />
                                    </Suspense>
                                }
                            />
                        )}
                        <Route
                            path={`${ROUTES.home}`}
                            element={
                                <Suspense
                                    fallback={<CenteredCircularProgress />}
                                >
                                    <LandingPage />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${ROUTES.file}`}
                            element={
                                <Suspense
                                    fallback={<CenteredCircularProgress />}
                                >
                                    <EmailVerification
                                        element={<NotationFileEditor />}
                                    />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${ROUTES.dashboard}`}
                            element={
                                <Suspense
                                    fallback={<CenteredCircularProgress />}
                                >
                                    <EmailVerification
                                        element={<Dashboard />}
                                    />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${ROUTES.errorEmailNotVerified}`}
                            element={
                                <Suspense
                                    fallback={<CenteredCircularProgress />}
                                >
                                    <VerifyEmail />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${ROUTES.home}`}
                            element={<Navigate to={`${ROUTES.signIn}`} />}
                        />

                        <Route
                            path="*"
                            element={
                                <Navigate to={`${ROUTES.home}`}></Navigate>
                            }
                        />
                    </Routes>
                </ErrorBoundary>
            }
        </>
    );
};

export default Router;
