import { Box, Button, Container, Link, Typography } from "@mui/material";
import firebaseApps from "../../config/firebase";
import { sendEmailVerification } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../core/routes";

export default function VerifyEmail() {
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const navigate = useRef(useNavigate());

    useEffect(() => {
        if (firebaseApps.auth.currentUser) {
            if (firebaseApps.auth.currentUser.emailVerified) {
                navigate.current(`${ROUTES.dashboard}`);
            }
        }
    });

    const sendVerificationEmail = () => {
        if (firebaseApps.auth.currentUser) {
            sendEmailVerification(firebaseApps.auth.currentUser).then(() => {
                setEmailSent(true);
            });
        }
    };

    return (
        <Container
            maxWidth="md"
            sx={{
                mt: 5,
                height: "80vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box sx={{ mt: 1, flexGrow: 1 }}>
                <Typography variant="h4">
                    Please verify your email address to continue.
                </Typography>
                <Button
                    variant="contained"
                    disabled={emailSent}
                    onClick={sendVerificationEmail}
                >
                    Send Verification Email
                </Button>

                {emailSent && (
                    <Box>
                        <Typography variant="h5">
                            Email has been sent. If your email is verified,
                            please try refreshing the page.
                        </Typography>
                        <Button
                            onClick={() => {
                                window.location.reload();
                            }}
                            variant="outlined"
                            color="secondary"
                        >
                            Refresh Window
                        </Button>
                    </Box>
                )}
            </Box>
            <Box>
                <Typography variant="h6">
                    For further help, please contact{" "}
                    <Link href="mailto:contact@notatia.org">
                        contact@notatia.org
                    </Link>
                </Typography>
            </Box>
        </Container>
    );
}
