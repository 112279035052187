import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";
import editorSlice from './slices/editor'


const rootReducer = combineReducers({
    firebase: firebaseReducer,
    editorSlice: editorSlice    
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            serializableCheck: false,
            thunk: {
                extraArgument: {
                    getFirebase,
                    getFirestore,
                },
            },
        }),
    ],
    devTools: process.env.NODE_ENV !== "production",
    enhancers: [],
});

export type AppDispatch = typeof store.dispatch;
export default store;
