export interface FirestoreDocument {
    [key: string]: any;
}

export function verifyKeys<T extends FirestoreDocument>(
    obj: any,
    keysInterface: T
): boolean {
    const objKeys = Object.keys(obj);
    const interfaceKeys = Object.keys(keysInterface);

    for (const key of interfaceKeys) {
        if (!objKeys.includes(key)) {
            return false;
        }
    }

    return true;
}

// Example usage
// const downloadedObject = {
//     name: "John Doe",
//     age: 30,
//     email: "johndoe@example.com",
// };

// interface MyInterface {
//     name: string;
//     age: number;
// }

// console.log(verifyKeys(downloadedObject, {} as MyInterface)); // Output: true
