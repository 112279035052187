import React, { useEffect, useRef } from "react";
import { Dummy } from "../../../core/Dummy";
import firebaseApps from "../../../config/firebase";
import ROUTES from "../../../core/routes";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../core/store/hooks";
import { RootState } from "../../../core/store/store";

interface IEmailVerificationProps {
    element: JSX.Element;
}

function EmailVerification(props: IEmailVerificationProps) {
    const navigate = useRef(useNavigate());
    const authIsLoaded = useAppSelector(
        (state: RootState) => state.firebase.auth.isLoaded
    );

    useEffect(() => {
        if (authIsLoaded && !firebaseApps.auth.currentUser?.emailVerified) {
            navigate.current(`${ROUTES.errorEmailNotVerified}`);
        }
    }, [authIsLoaded]);

    return <Dummy>{props.element}</Dummy>;
}

export default EmailVerification;
