import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";
import { HashRouter } from "react-router-dom";
import { IconButton, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import React, { createRef, MouseEventHandler } from "react";
// import { ReactReduxFirebaseProvider } from "react-redux-firebase";

// LI
import "./App.css";
import store from "./core/store/store";
import Router from "./core/Router";
import NavBar from "./components/NavBar";
import { createContext, useState, useEffect } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import firebaseApps from "./config/firebase";
import theme from "./static/theme";

interface IAuthContext {
    isLoaded: Boolean;
    auth: User | null;
    isAuthenticated: Boolean;
}

interface IAuthProviderProps {
    children: React.ReactElement;
}
const AuthProvider = (props: IAuthProviderProps) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [auth, setAuth] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const authObserver = onAuthStateChanged(firebaseApps.auth, (user) => {
            setIsLoaded(true);
            setAuth(user);
            setIsAuthenticated(!!user);
        });

        return () => {
            authObserver();
        };
    });

    const authContextValue: IAuthContext = {
        isLoaded,
        auth,
        isAuthenticated,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const AuthContext = createContext<IAuthContext>({
    isLoaded: false,
    auth: null,
    isAuthenticated: false,
});

function App() {
    const notistackRef: React.RefObject<SnackbarProvider> = createRef();
    const onClickDismiss: MouseEventHandler<HTMLButtonElement> = (key: any) => {
        notistackRef.current?.closeSnackbar();
    };
    document.title = "Notatia";
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider
                    maxSnack={3}
                    hideIconVariant
                    ref={notistackRef}
                    action={() => (
                        <IconButton onClick={onClickDismiss}>
                            <CloseIcon style={{ color: grey[100] }} />
                        </IconButton>
                    )}
                >
                    <AuthProvider>
                        <HashRouter basename="/">
                            <Box
                                sx={{
                                    minHeight: "100vh",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <NavBar />
                                <Router />
                            </Box>
                        </HashRouter>
                    </AuthProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
