import React from "react";
import firebaseApps from "../config/firebase";
import { Box } from "@mui/material";
import StyledFirebaseAuth from "../components/StyledFirebaseUI";
import * as fireabaseAuth from "firebase/auth";

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/signedIn",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        fireabaseAuth.GoogleAuthProvider.PROVIDER_ID,
        fireabaseAuth.EmailAuthProvider.PROVIDER_ID,
    ],
};

function BrokerAuth() {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                background: "background.default",
            }}
        >
            <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebaseApps.auth}
            ></StyledFirebaseAuth>
        </Box>
    );
}

export default BrokerAuth;
