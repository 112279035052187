import { Box, Grid, Paper, Typography } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { lazy, Suspense, useEffect, useState } from "react";
import CenteredCircularProgress from "../../../core/LoadingScreen";
import { FirestoreTaal } from "../../../interfaces/firebase";
import firebaseApps from "../../../config/firebase";
import { COMMON_SCRIPT_fetchTaal } from "../../../core/scripts";
const TaalList = lazy(() => import("./TaalList"));
const TaalEditorWindow = lazy(() => import("./TaalEditorWindow"));

function TaalEditor() {
    const [selectedTaal, setSelectedTaal] = useState<FirestoreTaal | null>(
        null
    );
    const [taalList, setTaalList] = useState<Array<FirestoreTaal> | null>(null);

    const getTaals = async () => {
        var tempList = await COMMON_SCRIPT_fetchTaal();
        setTaalList(tempList as Array<FirestoreTaal>);
        setSelectedTaal(null);
    };

    const setTaalFromId = (id: string): boolean => {
        if (taalList) {
            const selectedTaal = taalList.filter((taal) => taal.id === id);
            if (selectedTaal.length === 1) {
                setSelectedTaal(selectedTaal[0]);
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        if (!taalList) {
            getTaals();
        }
    }, [taalList]);

    const refreshTaalList = () => {
        setSelectedTaal(null);
        setTaalList(null);
    };

    const createNewTaal = async () => {
        await addDoc(collection(firebaseApps.firestore, "taal"), {
            name: "New Taal",
            detail: "",
            render: "",
        });
        refreshTaalList();
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Grid container sx={{ m: 2 }} maxWidth="lg" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3">Taal Editor</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={{ height: "70vh", p: 1 }}>
                        <Suspense fallback={<CenteredCircularProgress />}>
                            <TaalList
                                taalList={taalList}
                                setTaal={setTaalFromId}
                                addEmptyTaal={createNewTaal}
                                refresh={refreshTaalList}
                            />
                        </Suspense>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Paper sx={{ height: "70vh", p: 1 }}>
                        <Suspense fallback={<CenteredCircularProgress />}>
                            <TaalEditorWindow
                                selectedTaal={selectedTaal}
                                refresh={refreshTaalList}
                            />
                        </Suspense>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default TaalEditor;
