const ROUTES = {
    signIn: "/sign-in",
    dashboard: "/home",
    home: "/",
    logout: "/log-out",
    file: "/file/:fileId",
    errorEmailNotVerified:"/error-email",
    admin:"/admin"
};
export default ROUTES;
